/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SECRETS from './secrets';

import AppBar from './Components/AppBar';
import COLORS from './Components/styles/Colors';

const pageStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
};

const StyledTable = styled(Table)(() => ({
  borderRadius: 0,
  '& .MuiTableCell-head': {
    color: COLORS.SLATE50,
    backgroundColor: COLORS.SLATE700,
    borderRadius: 0,
    padding: '0.25rem 0.875rem',
  },
  '& .MuiTableCell-body': { padding: '0.25rem 0.875rem' },
}));

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const DelayScreen = ({
  token,
  logOut,
  checkSession,
  secondary,
}) => {
  const [isLogged, setIsLogged] = useState(true);
  const [dateFilter, setDateFilter] = useState('');
  const [recorridos, setRecorridos] = useState([[], []]);
  const [rutas, setRutas] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filtroRutas, setFiltroRutas] = useState([]);
  const [orderby, setOrderby] = useState('acc');
  const navigate = useNavigate();

  useEffect(() => {
    if (secondary === 'false') return;
    navigate('/');
  }, [secondary]);

  useEffect(() => { if (!checkSession()) setIsLogged(false); });

  useEffect(() => {
    const date = new Date();
    setDateFilter(`${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`);
  }, []);

  useEffect(() => {
    if (!isLogged) {
      logOut();
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    if (dateFilter !== '') {
      axios
        .get('/unidades/organizacion')
        .then((response) => {
          const unitsArray = response.data;
          // const unitsSorted = unitsArray.sort((a, b) => a.unidad.localeCompare(b.unidad));
          axios.defaults.headers.common.Authorization = `bearer ${token}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .post('/controlrecorrido', { fecha: dateFilter })
            .then((resp) => {
              axios.defaults.headers.common.Authorization = `bearer ${token}`;
              axios.defaults.baseURL = SECRETS.SERVERURL;
              axios
                .get('/controlpunto/all')
                .then((resppuntos) => {
                  const puntos = resppuntos.data;
                  const morning = [];
                  const afternoon = [];
                  resp.data
                    .filter((r) => !r.cancelado && r.terminado)
                    .forEach((recorrido) => {
                      // starts ignore last point
                      const rutaPuntos = puntos
                        .filter((p) => p.idControlRuta === recorrido.idControlRuta)
                        .sort((a, b) => a.tiempo - b.tiempo);
                      // ends ignora last point
                      const dateInicio = new Date(recorrido.inicio);
                      const delayTime = recorrido.puntos
                        .sort((a, b) => a.tiempo - b.tiempo)
                        .reduce((acc, p) => {
                          // ignorar recorrido si no llegó a la base y es la última del día
                          // starts ignore last point
                          const index = rutaPuntos.findIndex((punto) => punto.id === p.idControlPunto);
                          if (index === rutaPuntos.length - 1) {
                            // no acumular la última checada de cada recorrido
                            return acc;
                          }
                          // ends ignore last point
                          const ptime = new Date(p.fecha);
                          const punto = puntos.find((ps) => p.idControlPunto === ps.id);
                          if (typeof punto === 'undefined') {
                            return acc;
                          }
                          const diff = Math.floor(ptime / 1000 / 60) - (Math.floor(dateInicio) / 1000 / 60) - punto.tiempo;
                          if (diff <= 0) {
                            return acc;
                          }
                          return acc + diff;
                        }, 0);
                      const gmt = new Date(`${dateFilter}T19:30:00Z`); // 1pm cst
                      const unidad = unitsArray.find((u) => u.idUnidad === recorrido.idUnidad);
                      if (gmt - dateInicio >= 0) {
                        morning.push({
                          ...recorrido,
                          acc: delayTime,
                          unidad,
                        });
                      } else {
                        afternoon.push({
                          ...recorrido,
                          acc: delayTime,
                          unidad,
                        });
                      }
                    });
                  // reduce and accumulate all the time from the same operador
                  const recorridosM = {};
                  morning.forEach((r) => {
                    if (typeof recorridosM[r.idControlOperador] === 'undefined') {
                      recorridosM[r.idControlOperador] = {
                        recorridos: [{
                          ...r,
                        }],
                      };
                    } else {
                      recorridosM[r.idControlOperador].recorridos.push(r);
                    }
                  });
                  const recorridosA = {};
                  afternoon.forEach((r) => {
                    if (typeof recorridosA[r.idControlOperador] === 'undefined') {
                      recorridosA[r.idControlOperador] = {
                        recorridos: [{
                          ...r,
                        }],
                      };
                    } else {
                      recorridosA[r.idControlOperador].recorridos.push(r);
                    }
                  });
                  setRecorridos([
                    recorridosM,
                    recorridosA,
                  ]);
                })
                .catch((e) => {
                  console.error(e);
                });
            });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [dateFilter]);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controlruta')
      .then((response) => {
        setRutas(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  // const filterWithSearch = (row) => {
  //   const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  //   const matcher = new RegExp(`.*${removeTilde(searchValue.toLowerCase())}.*`);
  //   return (matcher.test(removeTilde(row.nombreRuta.toLowerCase()))
  //   || matcher.test(removeTilde(row.unidad.unidad.toLowerCase()))
  //   || matcher.test(removeTilde(row.nombreOperador.toLowerCase())));
  // };

  const liftingSearch = (searchValueString) => setSearchValue(searchValueString);

  const reduceAndFilter = (turno, operador) => {
    const total = recorridos[turno][operador].recorridos.reduce((acc, recorrido) => {
      if (filtroRutas.length === 0 || filtroRutas.includes(recorrido.idControlRuta)) {
        return {
          ...acc,
          ...recorrido,
          count: acc.count + 1,
          acc: acc.acc + recorrido.acc,
        };
      }
      return {
        ...recorrido,
        ...acc,
      };
    }, { acc: 0, count: 0 });
    return {
      ...operador,
      ...total,
      avg: Math.round((total.acc / total.count)),
    };
  };

  return (
    <div style={pageStyle}>
      <AppBar
        visibleStateRows={[]}
        dateFilter={dateFilter}
        filterRoutes={filtroRutas}
        rutas={rutas}
        newTracksList={undefined}
        searchBarTextDisplay={searchValue}
        isVisibleNewTracksLength={false}
        secondary={secondary}
        handleSearch={liftingSearch}
        handleLogOut={() => setIsLogged(false)}
        handleStateRow={false}
        handleFilterRoutes={(filtro) => setFiltroRutas(filtro)}
        handleDateFilter={(dateFilterValue) => setDateFilter(dateFilterValue)}
        handleUptTracks={undefined}
        location="Atrasos"
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer className="last-checkpoint-table">
          <StyledTable stickyHeader aria-label="collapsible table">
            <TableHead style={{ borderRadius: 0 }}>
              <TableRow>
                <TableCell
                  align="center"
                  variant="head"
                  padding="none"
                  colSpan="1"
                >
                  Operador
                </TableCell>
                <TableCell
                  align="center"
                  variant="head"
                  padding="none"
                  colSpan="1"
                >
                  Unidad
                </TableCell>
                <TableCell
                  align="center"
                  variant="head"
                  padding="none"
                  colSpan="1"
                  onClick={() => setOrderby('acc')}
                >
                  {`Acumulado ${orderby === 'acc' ? '↓' : ''}`}
                </TableCell>
                <TableCell
                  align="center"
                  variant="head"
                  padding="none"
                  colSpan="1"
                  onClick={() => setOrderby('count')}
                >
                  {`# Recorridos ${orderby === 'count' ? '↓' : ''}`}
                </TableCell>
                <TableCell
                  align="center"
                  variant="head"
                  padding="none"
                  colSpan="1"
                  onClick={() => setOrderby('avg')}
                >
                  {`Promedio por vuelta ${orderby === 'avg' ? '↓' : ''}`}
                </TableCell>
                <TableCell
                  align="center"
                  variant="head"
                  padding="none"
                  colSpan="1"
                >
                  Turno
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(recorridos[0])
                ?.map((operador) => reduceAndFilter(0, operador))
                ?.sort((a, b) => b[orderby] - a[orderby])
                .filter((i) => {
                  const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                  const stringToSearch = searchValue.toLowerCase() || '';
                  const regex = new RegExp(`.*${removeTilde(stringToSearch)}.*`);
                  return (
                    regex.test(removeTilde(i.nombreOperador.toLowerCase()))
                  );
                })
                .filter((i) => {
                  if (filtroRutas.length === 0) {
                    return true;
                  }
                  return filtroRutas.includes(i.idControlRuta);
                })
                .map((row) => (
                  <TableRow key={`recorrido-${row.id}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.nombreOperador}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.unidad.unidad}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.acc}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.count}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.avg}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">Matutino</TableCell>
                  </TableRow>
                ))}
              {Object.keys(recorridos[1])
                ?.map((operador) => reduceAndFilter(1, operador))
                ?.sort((a, b) => b[orderby] - a[orderby])
                .filter((i) => {
                  const removeTilde = (text) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                  const stringToSearch = searchValue.toLowerCase() || '';
                  if (stringToSearch === '') {
                    return true;
                  }
                  const regex = new RegExp(`.*${removeTilde(stringToSearch)}.*`);
                  return regex.test(removeTilde(i.nombreOperador.toLowerCase()));
                })
                .map((row) => (
                  <TableRow key={`recorrido-${row.id}`} sx={{ '& > *': { borderBottom: 'unset' } }} style={{ backgroundColor: '#f7f7f7' }}>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.nombreOperador}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.unidad.unidad}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.acc}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.count}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">{row.avg}</TableCell>
                    <TableCell variant="body" padding="none" component="th" scope="row" align="center">Vespertino</TableCell>
                  </TableRow>
                ))}
              {recorridos.length === 0 && (
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell variant="body" padding="none" component="th" scope="row" align="center">
                    No hay registros de este día.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default DelayScreen;
